<template>
  <div>
    <section>
      <article class="contacts container" style="padding-top: 200px; padding-bottom: 50px;">
          <div style="width: 80%; text-align: left;">
            <h1>Contacts</h1>
            <div class="row mt-4">
              <div class="col-md-6">
                <div>
                  <h3>Corporate Communications</h3>
                  <ul>
                    <li>Tel: +256393248550</li>
                    <li>Email: info@locushoteluganda.com</li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6">
                <div>
                  <h3>Sales & Marketing Department</h3>
                  <ul>
                    <li>Tel: +256776946591</li>
                    <li>Email: sales@locushoteluganda.com</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
      </article>
    </section>

    <section>
      <article>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63836.24809858515!2d32.522070538254084!3d0.2948063449348795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177dbd676ac3195b%3A0xce7c2f95a88d9e9e!2sLocus%20Hotel%20Makindye!5e0!3m2!1sen!2sug!4v1645003542657!5m2!1sen!2sug" width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </article>
    </section>
  </div>
</template>

<script>
export default {

  data() {
    return {};
  },

  methods: {
    verifyPhoneNUmber() {
      this.$router.push({ path: `verify-phone-number` });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  * {
    font-family: 'Lato', sans-serif;
  }

  ul {
    list-style: none;
    margin: 0px;
    padding:0px;
    font-weight: 300;
  }

  h3{
    font-size: 1.1em;
    font-weight: 600;
    margin-bottom: 15px;
  }

</style>
